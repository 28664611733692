<template>
  <div id="app">
    <div class="wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      scroll_1: false,
      scroll_2: false,
      scroll_3: false,
      scroll_4: false,
      scroll_5: false,
      scroll_6: false,
      scroll_7: false,
    }
  },
  mounted() {
    this.$gtag.pageview({page_path: '/'})
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      let fullH = document.documentElement.getBoundingClientRect().height;
      let topH = window.pageYOffset;
      let procentScroll = topH/fullH*100;
      if (procentScroll > 20 && !this.scroll_1) {
        this.scroll_1 = true;
        this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_1'})
      }
      if (procentScroll > 35 && !this.scroll_2) {
        this.scroll_2 = true;
        this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_2'})
      }
      if (procentScroll > 50 && !this.scroll_3) {
        this.scroll_3 = true;
        this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_3'})
      }
      if (procentScroll > 65 && !this.scroll_4) {
        this.scroll_4 = true;
        this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_4'})
      }
      if (procentScroll > 70 && !this.scroll_5) {
        this.scroll_5 = true;
        this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_5'})
      }
      if (procentScroll > 85 && !this.scroll_6) {
        this.scroll_6 = true;
        this.$gtag.event('click', {event_category: 'link','event_label': 'scroll_6'})
      }
    },
  }
}
</script>

<style>
  html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}
</style>

<style lang="sass">
  #app
    position: relative
    overflow: hidden
    min-height: 100vh
    display: block
    font-family: 'Tautz', sans-serif
    background: linear-gradient(180deg, #BFBFCF 2.51%, #D9D8DF 48%, rgba(217, 216, 223, 0.27) 100%)
    background-color: #D9D7DF
  *
    box-sizing: border-box
  body
    font-family: 'Tautz', sans-serif
    overflow-x: hidden
    position: relative
  .container
    width: 1064px
    margin: auto
    position: relative
    @media screen and (max-width: 1000px)
      width: 100%
</style>

<style>
@font-face {
    font-family: 'Sverdlovsk';
    src: url('./assets/fonts/subset-SverdlovskRegular.woff2') format('woff2'),
        url('./assets/fonts/subset-SverdlovskRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tautz';
    src: url('./assets/fonts/subset-Tautz-Regular.woff2') format('woff2'),
        url('./assets/fonts/subset-Tautz-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

</style>
<template>
	<div class="arcs">
		<div class="container">
			<div class="arcs__left">
				<img src="../assets/img/arc-4l.png" class="anim-rotate" alt="">
			</div>
			<div class="arcs__image" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866848884&iu=/81006599/hmiru-mc/count', 'COLLECTION_4')">
				<div class="el-4-1"></div>
				<div class="el-4-2"></div>
				<div class="el-4-3"></div>
				<div class="el-4-4"></div>
				<div class="el-4-5"></div>
				<div class="el-4-6"></div>
				<div class="el-4-7"></div>
			</div>
			<div class="arcs__right">
				<img src="../assets/img/arc-4r.png" class="anim-rotate-rev" alt="">
			</div>
			<div class="arcs__content">
				<div class="arcs__title" data-aos="fade">Искусство <span></span>быть грациозной</div>
				<div class="arcs__arrows" data-aos="zoom-in"></div>
				<div class="arcs__subtitle" data-aos="fade">Укрепление кожи с The Firming <span></span>Moisture Collection от La Mer </div>
				<div class="arcs__text" data-aos="fade">
					<p>Грациозность часто ассоциируется <span></span>с благородством <br/>и аристократичностью образа. Эта составляющая женственности отвечает за ее силу, интенсивность <br/>и непосредственность. Она награждает ее своим особенным «почерком» и энергией, способной покорять сердца. Первый и самый важный шаг на пути<span></span> <br/>к грациозному образу – комплексное укрепление кожи при помощи набор The Firming Moisture Collection <br/><span></span>от La Mer, котороый поможет ей поддерживать безупречный внешний вид на повседневной основе. </p>
					<div class="product-m" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925021&iu=/81006599/hmiru-mc/count', 'PRODUCT_3')">
						<div class="product__item-image"><img src="../assets/img/p-11.png" alt=""></div>
						<div class="product__item-box">
							<div class="product__item-title">The Moisturizing Soft Cream</div>
							<div class="product__item-text">Крем для лица</div>
						</div>
					</div>
					<p>Это идеальное решение для того, чтобы добиться заметного результата в работе над этой непростой задачей и подарить себе порцию истинного наслаждения от уходового ритуала. Концентрат <br/>для шеи и зоны декольте The Neck and Décolleté Concentrate обеспечит коже молодой вид и глубокое питание. Увлажняющий крем The Moisturizing Soft Cream с невероятно легкой текстурой поможет вернуть кожу в зону комфорта, подарив ей ощущение легкости <br/>и воздушности.</p>
				</div>
			</div>
			<div class="product">
				<div class=" rellax"  data-rellax-speed="0.2" data-rellax-percentage="0.5">
					<div class="product__item product__item--1" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925021&iu=/81006599/hmiru-mc/count', 'PRODUCT_3')">
						<div class="product__item-image"><img src="../assets/img/p-11.png" alt=""></div>
						<div class="product__item-box">
							<div class="product__item-title">The Moisturizing Soft Cream</div>
							<div class="product__item-text">Крем для лица</div>
						</div>
					</div>
				</div>
				<div class=" rellax"  data-rellax-speed="-0.2" data-rellax-percentage="0.5">
					<div class="product__item product__item--2" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866851035&iu=/81006599/hmiru-mc/count', 'PRODUCT_7')">
						<div class="product__item-box">
							<div class="product__item-title">The Neck and Décolleté Concentrate</div>
							<div class="product__item-text">Концентрат <br/>для шеи и зоны декольте </div>
						</div>
						<div class="product__item-image"><img src="../assets/img/p-12.png" alt=""></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Arcs',
		methods: {
			openLink(link, event_label) {
				window.open(link, '_blank').focus();
        this.$gtag.event('click', {event_category: 'link','event_label': event_label})
			},
		}
	}
</script>

<style lang="sass" scoped>
	.arcs__image--desk
		display: block
		@media screen and (max-width: 1000px)
			display: none
	.arcs__image--mob
		display: none
		@media screen and (max-width: 1000px)
			display: block
	@keyframes pr1
		0%
			transform: rotate(0deg)
		60%
			transform: translateY(-250px)
		80%
			transform: rotate(10deg) translateY(-300px)
		100%
			transform: rotate(0deg)
	@keyframes pr2
		0%
			transform: rotate(0deg)
		30%
			transform: translateY(-350px)
		50%
			transform: rotate(10deg) translateY(-400px)
		100%
			transform: rotate(0deg)
	.el-4-1
		width: 486px
		height: 574px
		display: block
		background-image: url(../assets/img/a4/el-3.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 7
		top: 102px	
		left: 83px
		animation: rotate
		animation-duration: 43s
		animation-timing-function: linear
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 72vw
			height: 72vw
			left: 21vw
			right: 10vw
			margin: auto
			top: 24vw
	.el-4-4
		width: 486px
		height: 574px
		display: block
		background-image: url(../assets/img/a4/el-3.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 7
		top: 102px	
		left: 83px
		animation: rotate
		animation-duration: 33s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
		@media screen and (max-width: 1000px)
			width: 72vw
			height: 72vw
			left: 18vw
			right: 10vw
			margin: auto
			top: 24vw
	.el-4-5
		width: 600px
		height: 318px
		display: block
		background-image: url(../assets/img/a4/el-5.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 10
		top: 445px
		left: -52px
		@media screen and (max-width: 1000px)
			width: 81vw
			height: 72vw
			left: -2vw
			right: 20vw
			margin: auto
			top: 67vw
	.el-4-6
		width: 	200px
		height: 200px
		display: block
		background-image: url(../assets/img/a4/el.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 8
		top: 426px
		left: 302px
		animation: pr1
		animation-duration: 15s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite	
		@media screen and (max-width: 1000px)
			width: 30vw
			height: 32vw
			left: -2vw
			right: -29vw
			margin: auto
			top: 67vw	
	.el-4-7
		width: 	254px
		height: 254px
		display: block
		background-image: url(../assets/img/a4/el-2.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 8
		top: 426px
		left: 302px
		animation: pr2
		animation-duration: 15s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite		
		@media screen and (max-width: 1000px)
			width: 30vw
			height: 32vw
			left: -2vw
			right: -29vw
			margin: auto
			top: 67vw	
	.el-4-2
		width: 294px
		height: 260px
		display: block
		background-image: url(../assets/img/a4/el-4.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 7
		top: 297px	
		left: 63px
		animation: rotate
		animation-duration: 43s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 72vw
			height: 72vw
			left: -2vw
			right: 10vw
			margin: auto
			top: 24vw
	.el-4-3
		width: 176px
		height: 198px
		display: block
		background-image: url(../assets/img/a4/el-3.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 7
		top: 254px	
		left: 425px
		animation: rotate
		animation-duration: 43s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 72vw
			height: 72vw
			left: -2vw
			right: 10vw
			margin: auto
			top: 24vw
	.anim-rotate
		animation: rotate
		animation-duration: 110s
		animation-timing-function: linear
		animation-iteration-count: infinite
	.anim-rotate-rev
		animation: rotate2
		animation-duration: 110s
		animation-direction: reverse
		animation-timing-function: linear
		animation-iteration-count: infinite
	.product-m
		@media screen and (max-width: 1000px)
			background-image: url(../assets/img/product-4-bg-m.png)
			margin-top: -12vw
			margin-bottom: 1vw
			background-size: 100vw
			min-height: 144.66667vw
			width: vwm(347px)
			display: flex
			flex-direction: column
			background-position: vwm(-16px) 0
		.product__item-box
			@media screen and (max-width: 1000px)
				margin-top: 2vw
				margin-left: vwm(97px)
		.product__item-image
			@media screen and (max-width: 1000px)
				margin-top: vwm(179px)
				img
					width: vwm(153px)
					position: relative
					top: 0
					left: 6.2vw
	.arcs__subtitle
		@media screen and (max-width: 1000px)
			span
				display: block
	.arcs__text
		@media screen and (max-width: 1000px)
			font-size: vwm(18px)
			line-height: vwm(23px)
			width: 94%
			p:last-child
				left: 0
				width: 93%
	.arcs
		margin-top: -19px
	.arcs__left img
		margin-left: -32px
		margin-top: -233px
	.arcs__right img
		margin-left: -125px
		margin-top: -18px
	.arcs__image
		background-image: url(../assets/img/a4/bg.jpg)
	.arcs__left
		&:after
			background: linear-gradient(180deg, #D2C8BE 0%, #C5D7BF 0.01%, rgba(244, 246, 241, 0.21) 72.4%, rgba(243, 246, 241, 0) 100%)
	.arcs__right
		&:after
			background: linear-gradient(180deg, #D2C8BE 0%, #C5D7BF 0.01%, rgba(244, 246, 241, 0.21) 72.4%, rgba(243, 246, 241, 0) 100%)
	@media screen and (max-width: 1000px)
		.product
			background-image: url(../assets/img/products-bg-4-m.png)
			background-size: 100vw
			background-position: 50% 0
			margin: auto
			margin-top: vwm(-98px)
			&__item
				&--1
					display: none
				&--2
					margin-left: 0
					.product__item-title
						left: 0
						top: 1vw
					.product__item-text
						width: 60vw
					.product__item-box
						width: 100vw
						left: 0
						top: 68vw
					img
						width: 114vw
						margin-left: -10vw
						margin-top: 5vw
	@media screen and (min-width: 1000px)
		.product
			background-image: url(../assets/img/product-bg-4.png)
			margin-top: 47px
			margin-left: 0px
			background-size: 879px
			background-position: -4px 5px
			&__item
				&--1
					img
						width: 173px
						margin-top: 87px
						margin-left: 221px
					.product__item-box
						width: 222px
						margin-left: -52px
						margin-top: -225px
				&--2
					img
						width: 471px
						margin-top: -238px
						margin-left: 8px
					.product__item-box
						width: 290px
						margin-left: 92px
						margin-top: -469px
					.product__item-text
						font-size: 19px
						line-height: 22px
</style>
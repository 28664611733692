<template>
	<div class="arcs">
		<div class="container">
			<div class="arcs__left">
				<img class="parallax" data-speed-y="10" data-speed-x="-15" src="../assets/img/arc-2l.png" alt="">
				<img class="parallax" data-speed-y="-10" data-speed-x="15" src="../assets/img/arc-2l2.png" alt="">
				<img class="parallax" data-speed-y="30" data-speed-x="14" src="../assets/img/arc-2l3.png" alt="">
			</div>
			<div class="arcs__image" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864920017&iu=/81006599/hmiru-mc/count', 'COLLECTION_2')">
					<div class="el2-1"></div>
					<div class="el2-2"></div>
					<div class="el2-3"></div>
					<div class="el2-4"></div>
					<div class="el2-5"></div>
					<div class="el2-6"></div>
					<div class="el2-7"></div>
			</div>
			<div class="arcs__right">
				<img src="../assets/img/arc-2r.png" class="parallax" data-speed-y="10" data-speed-x="-15" alt="">
			</div>
			<div class="arcs__content">
				<div class="arcs__title" data-aos="fade">Искусство <span></span>быть элегантной</div>
				<div class="arcs__arrows" data-aos="zoom-in"></div>
				<div class="arcs__subtitle" data-aos="fade">Наполнение и лифтинг кожи с The <span></span>Replenish and Lift Collection от La Mer</div>
				<div class="arcs__text" data-aos="fade">
					<p>Элегантность – еще один компонент женственности, который дарит образу каждой женщины неповторимый шарм. Элегантность – тот шлейф, который оставляет <br/>за собой ваш образ и который делает его запоминающимся, непохожим ни на один другой. Элегантность бьюти-образа во многом зависит от ухоженности кожи – ее наполненности и подтянутости, которые позволяют вашей внешности раскрыться <br/>в полной красоте и предъявить свою индивидуальность миру. Чтобы поддержать баланс кожи и приблизить ее состояние к идеальному, можно воспользоваться бьюти-сетом от La Mer с тремя эффективными уходовыми средствами.</p>
					<div class="product-m" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925024&iu=/81006599/hmiru-mc/count', 'PRODUCT_4')">
						<div class="product__item-image"><img src="../assets/img/p-4-m.png" alt=""></div>
						<div class="product__item-box">
							<div class="product__item-title">The Lifting & Firming Mask</div>
							<div class="product__item-text">Лифтинг-маска для лица</div>
						</div>
					</div>
					<p>Средства из сета The Replenish and Lift Collection позволяют быстро вернуть коже свежесть <br/>и нормализовать происходящие в ней процессы. Ухаживающий лосьон The Treatment Lotion запускает процессы естественного восстановления кожи <br/>и заряжает ее энергией. Назначение лифтинг-маски <br/>The Lifting & Firming Mask – визуальная подтяжка кожи лица и шеи, которая заметно преобразит ваш внешний вид и придаст ему большую очерченность. Увлажняющий крем Crème de la Mer – завершающий уходовый этап для создания элегантного бьюти-лука: средство насыщает кожу питательной влагой и придает ей наполненность.</p>
				</div>
			</div>
			<div class="product">
				<div class=" rellax"  data-rellax-speed="-0.2" data-rellax-percentage="0.5">
					<div class="product__item product__item--1" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925024&iu=/81006599/hmiru-mc/count', 'PRODUCT_4')">
						<div class="product__item-image" v-if="!toggleProduct"><img src="../assets/img/p-4.png" alt=""></div>
						<div class="product__item-image" v-else><img src="../assets/img/p-4-2.png" alt=""></div>
						<div class="product__item-box">
							<div class="product__item-title">The Lifting & Firming Mask</div>
							<div class="product__item-text">Лифтинг-маска для лица</div>
						</div>
					</div>
				</div>
				<div class=" rellax"  data-rellax-speed="0.2" data-rellax-percentage="0.5">
					<div class="product__item product__item--2" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925009&iu=/81006599/hmiru-mc/count', 'PRODUCT_1')">
						<div class="product__item-box">
							<div class="product__item-title">The Treatment Lotion</div>
							<div class="product__item-text">Лосьон для лица</div>
						</div>
						<div class="product__item-image"><img src="../assets/img/p-5.png" alt=""></div>
					</div>
				</div>
				<div class="product__item product__item--3" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866186222&iu=/81006599/hmiru-mc/count', 'PRODUCT_5')">
					<div class="product__item-box">
						<div class="product__item-title">Crème de <br>la Mer</div>
						<div class="product__item-text">Увлажняющий крем</div>
					</div>
					<div class="product__item-image"><img src="../assets/img/p-6.png" alt=""></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Arcs',
		data() {
			return {
				toggleProduct: false,
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
		},
		methods: {
			openLink(link, event_label) {
				window.open(link, '_blank').focus();
        this.$gtag.event('click', {event_category: 'link','event_label': event_label})
			},
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				if (procentScroll > 40) {
					this.toggleProduct = true;
				} else {
					this.toggleProduct = false;
				}
			},
		}
	}
</script>

<style lang="sass" scoped>
	@keyframes updown5
		0%
			transform: translateY(0px) translateX(0)
		50%
			transform: translateY(180px) translateX(-300px)
		100%
			transform: translateY(0px) translateX(0)
	@keyframes zoom1
		0%
			transform: scale(1) rotate(0)
		50%
			transform: scale(1.3) rotate(10deg)
		100%
			transform: scale(1) rotate(0)
	.el2-1
		width: 579px
		height: 856px
		left: 30px
		top: 72px
		background-image: url(../assets/img/a2/el-1.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 10
		@media screen and (max-width: 1000px)
			width: vwm(300px)
			top: vwm(40px)
			left: vwm(20px)
	.el2-2
		width: 49px
		height: 49px
		left: 588px
		top: 288px
		background-image: url(../assets/img/a2/el-3.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 10
		animation: updown5
		animation-duration: 14s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
		@media screen and (max-width: 1000px)
			width: 7vw
			height: 7vw
			left: 82vw
			top: 39vw
	.el2-3
		width: 17px
		height: 17px
		left: 579px
		top: 762px
		background-image: url(../assets/img/a2/el-3.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 10
		animation: updown4
		animation-duration: 14s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
		@media screen and (max-width: 1000px)
			left: 80vw
			height: 3vw
			width: 3vw
			top: 118vw
	.el2-4
		width: 290px
		height: 452px
		left: 29px
		top: 16px
		background-image: url(../assets/img/a2/el-2.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 8
		animation: zoom1
		animation-duration: 15s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse	
	.el2-5
		width: 290px
		height: 452px
		left: 329px
		top: 316px
		background-image: url(../assets/img/a2/el-2.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 8
		animation: zoom1
		animation-duration: 15s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
	.el2-6
		width: 309px
		height: 309px
		left: 329px
		top: 216px
		background-image: url(../assets/img/a2/el-3.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 8
		animation: updown4
		animation-duration: 15s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
		@media screen and (max-width: 1000px)
			width: 40vw
			height: 40vw
			left: 20vw
			top: 60vw
	.el2-7
		width: 127px
		height: 195px
		left: 589px
		top: 123px
		background-image: url(../assets/img/a2/el-4.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 11
		animation: rotate
		transform-origin: center center
		animation-duration: 25s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
	.arcs__left img
		margin-left: 77px
		margin-top: 6px
	.arcs__right img
		margin-left: -86px
		margin-top: 68px
	.product-m
		@media screen and (max-width: 1000px)
			background-image: url(../assets/img/product-2-m-bg.png)
			margin-top: vwm(71px)
			margin-bottom: vwm(77px)
			background-size: vwm(347px)
			min-height: vwm(445px)
			width: vwm(347px)
		.product__item-box
			@media screen and (max-width: 1000px)
				margin-top: vwm(-64px)
		.product__item-image
			@media screen and (max-width: 1000px)
				img
					width: vwm(355px)
					position: relative
					top: vwm(-58px)
					left: vwm(-17px)
	.arcs__text
		@media screen and (max-width: 1000px)
			font-size: vwm(18px)
			line-height: vwm(23px)
			width: 94%
	.arcs__subtitle
		@media screen and (max-width: 1000px)
			span
				display: block
	.arcs__image
		background-image: url(../assets/img/a2/bg.jpg)
		@media screen and (max-width: 1000px)
			margin-top: vwm(121px)
	.arcs__left
		&:after
			background: linear-gradient(180deg, #ABB0B6 0%, rgba(200, 204, 207, 0.91) 71.35%, rgba(218, 219, 223, 0) 100%)
	.arcs__right
		&:after
			background: linear-gradient(180deg, #ABB0B6 0%, rgba(200, 204, 207, 0.91) 71.35%, rgba(218, 219, 223, 0) 100%)
	@media screen and (max-width: 1000px)
		.product
			background-image: url(../assets/img/products-bg-2-m.png)
			margin-top: vwm(192px)
			background-size: 97.8vw
			&__item
				&--1
					display: none
				&--2
					img
						width: vwm(345px)
						margin-top: vwm(-363px)
						margin-left: vwm(-210px)
					.product__item-box
						width: vwm(200px)
						margin-left: vwm(36px)
						margin-top: vwm(-466px)
					.product__item-text
						top: 1vw
						left: 20.3vw
				&--3
					display: block !important
					left: 76vw
					top: 121vw
					img
						width: 49vw
						margin-top: -92.8vw
						margin-left: -37vw
					.product__item-box
						width: 53.33333vw
						margin-left: -40.4vw
						margin-top: -38vw
						top: 0
						left: 0
					.product__item-text
						top: 1vw
						left: 20.3vw
	@media screen and (min-width: 1000px)
		.product
			background-image: url(../assets/img/products-bg-2.png)
			margin-top: 175px
			margin-left: 47px
			background-size: 898px
			background-position: 6px 0
			&__item
				&--1
					img
						width: 442px
						margin-top: -187px
						margin-left: -95px
					.product__item-box
						width: 222px
						margin-left: 50px
						margin-top: -6px
				&--2
					img
						width: 435px
						margin-top: -446px
						margin-left: 36px
					.product__item-box
						width: 222px
						margin-left: -333px
						margin-top: -605px
				&--3
					img
						width: 282px
						margin-top: 100px
						margin-left: -110px
					.product__item-box
						width: 222px
						margin-left: -17px
						margin-top: -251px
</style>
<template>
	<div class="arcs" id="b2">
		<div class="container">
			<div class="arcs__left" data-aos="fade-up" data-aos-delay="200">
				<img src="../assets/img/arc-1l2.png" alt="" class="parallax2" data-speed-y="10" data-speed-x="-15">
				<img src="../assets/img/arc-1l3.png" alt="" class="parallax2" data-speed-y="-20" data-speed-x="25">
				<img src="../assets/img/arc-1l.png" alt="" class="parallax2" data-speed-y="30" data-speed-x="14">
			</div>
			<div class="arcs__image" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864921661&iu=/81006599/hmiru-mc/count', 'COLLECTION_1')">
				<div class="el-1"></div>
				<div class="el-2"></div>
				<div class="el-3"></div>
				<div class="el-4"></div>
				<div class="el-5"></div>
				<div class="el-6"></div>
				<div class="el-7"></div>
				<div class="el-8"></div>
				<div class="f-1"></div>
			</div>
			<div class="arcs__right" data-aos="fade-up" data-aos-delay="600">
				<img src="../assets/img/arc-1r.png" class="parallax2" data-speed-y="10" data-speed-x="-15" alt="">
			</div>
			<div class="arcs__content">
				<div class="arcs__title" data-aos="fade">Искусство <span></span>быть сексуальной</div>
				<div class="arcs__arrows" data-aos="zoom-in"></div>
				<div class="arcs__subtitle" data-aos="fade">Увлажнение кожи с The Radiant Hydration Collection от La Mer</div>
				<div class="arcs__text" data-aos="fade">
					<p>Сексуальность – то, что заложено <span></span>в женщину природой, но способность раскрыть ее в полную силу – искусство, которое постигается каждой представительницей прекрасного пола индивидуально. Развитие ее многогранности и силы требует не  только времени <br/>и внутренней работы над собой, но и применения эффективных средств, позволяющих подчеркнуть свою женственность с помощью ежедневных бьюти-ритуалов. Сексуальный образ – это прежде всего увлажненная, напитанная и сияющая здоровьем кожа, которая нуждается в минимальном количестве косметики, <br/>чтобы выглядеть соблазнительно молодо. </p>
					<div class="product-m" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925009&iu=/81006599/hmiru-mc/count', 'PRODUCT_1')">
						<div class="product__item-image"><img src="../assets/img/p-3.png" alt=""></div>
						<div class="product__item-box">
							<div class="product__item-title">The Treatment Lotion</div>
							<div class="product__item-text">Лосьон для лица</div>
						</div>
					</div>
					<p>Добиться подобного эффекта возможно <span></span>с помощью трех средств из набора The Radiant Hydration Collection от La Mer. Лосьон The Treatment Lotion заряжает кожу энергией, возвращая ей отдохнувший вид. Длительное тонизирование подарит увлажняющая сыворотка The Revitalizing Hydrating Serum: в ее формуле сконцентрирован морской заряд, который устроит коже полноценный детокс и вернет ей ощущение, как после целого дня, проведенного на побережье. Крем The Moisturizing Soft Cream – артистичный штрих, который поможет высвободить внутреннее сияние кожи и придать бьюти-образу больше яркости <span></span>и насыщенности.</p>
				</div>
			</div>
			<div class="product">
				<div class=" rellax"  data-rellax-speed="0.2" data-rellax-percentage="0.5">
					<div class="product__item product__item--1" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925021&iu=/81006599/hmiru-mc/count', 'PRODUCT_3')">
						<div class="product__item-title">The Moisturizing Soft Cream</div>
						<div class="product__item-text">Крем для лица</div>
						<div class="product__item-image"><img src="../assets/img/p-1.png" alt=""></div>
					</div>
				</div>
				<div class=" rellax"  data-rellax-speed="-0.2" data-rellax-percentage="0.5">
					<div class="product__item product__item--2" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925015&iu=/81006599/hmiru-mc/count', 'PRODUCT_2')">
						<div class="product__item-box">
							<div class="product__item-title">The Revitalizing Hydrating Serum</div>
							<div class="product__item-text">Увлажняющая сыворотка</div>
						</div>
						<div class="product__item-image"><img src="../assets/img/p-2.png" class="desk-only" alt=""><img src="../assets/img/p-2-m.png" class="mob-only" alt=""></div>
					</div>
				</div>
				<div class=" rellax"  data-rellax-speed="0.5" data-rellax-percentage="0.5">
					<div class="product__item product__item--3" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925009&iu=/81006599/hmiru-mc/count', 'PRODUCT_1')">
						<div class="product__item-box">
							<div class="product__item-title">The Treatment Lotion</div>
							<div class="product__item-text">Лосьон для лица</div>
						</div>
						<div class="product__item-image"><img src="../assets/img/p-3.png" alt=""></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Rellax from 'rellax';
	import gsap from 'gsap';
	export default {
		name: 'Arcs',
		data() {
			return {
				play: false,
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
			window.addEventListener("mousemove", this.parallaxEvent);
			new Rellax('.rellax');
		},
		methods: {
			openLink(link, event_label) {
				window.open(link, '_blank').focus();
        this.$gtag.event('click', {event_category: 'link','event_label': event_label})
			},
			parallaxEvent() {
				var pageX =  event.pageX - (document.getElementById('b2').getBoundingClientRect().width * 0.5);
				var pageY =  event.pageY - (document.getElementById('b2').getBoundingClientRect().height * 0.5);
				var i = 0;
				let parallaxItems = document.getElementsByClassName('parallax2');
				for (i = 0; i < parallaxItems.length; i++) {
						var item = parallaxItems[i];
						var speedX = item.getAttribute("data-speed-x") || 30;
						var speedY = item.getAttribute("data-speed-y") || 30;
						gsap.set(item, {
								x: (item.offsetLeft + pageX * speedX ) * 0.001,
								y: (item.offsetTop + pageY * speedY) * 0.001
						});
				}
			},
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				if (procentScroll > 7 && !this.play) {
					this.play = true;
				}
			},
		}
	}
</script>

<style lang="sass">
	@keyframes updown
		0%
			transform: translateY(0px)
		50%
			transform: translateY(-50px)
		100%
			transform: translateY(0px)
	@keyframes updown2
		0%
			transform: translateY(0px)
		50%
			transform: translateY(-20px)
		100%
			transform: translateY(0px)
	@keyframes updown3
		0%
			transform: translateY(0px)
		50%
			transform: translateY(300px)
		100%
			transform: translateY(0px)
	@keyframes updown4
		0%
			transform: translateY(0px)
		50%
			transform: translateY(-380px)
		100%
			transform: translateY(0px)
	@keyframes rotate1
		0%
			transform: rotate(0)
		50%
			transform: rotate(380deg)
		100%
			transform: rotate(0)
	.el-4
		width: 144px
		height: 144px
		left: -22px
		top: 69px
		background-image: url(../assets/img/a1/el-3.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 10
		animation: rotate1
		animation-duration: 28s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
		@media screen and (max-width: 1000px)
			width: vwm(79px)
			height: vwm(79px)
			left: vwm(294px)
			top: vwm(137px)
	.el-6
		width: 25px
		height: 25px
		left: 235px
		top: 320px
		background-image: url(../assets/img/a1/el-3.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 10
		animation: updown4
		animation-duration: 13s
		animation-timing-function: linear
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: vwm(12px)
			height: vwm(12px)
			left: vwm(123px)
			top: vwm(220px)
	.el-7
		width: 60px
		height: 60px
		left: 367px
		top: 28px
		background-image: url(../assets/img/a1/el-3.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 1
		animation: updown3
		animation-duration: 13s
		animation-timing-function: linear
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: vwm(42px)
			height: vwm(42px)
			left: vwm(203px)
			top: vwm(20px)
	.el-3
		width: 144px
		height: 144px
		left: 536px
		top: 268px
		background-image: url(../assets/img/a1/el-3.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 10
		animation: rotate1
		animation-duration: 38s
		animation-timing-function: linear
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: vwm(79px)
			height: vwm(79px)
			left: vwm(-14px)
			top: vwm(37px)
	.el-8
		width: 144px
		height: 144px
		left: 286px
		top: 698px
		background-image: url(../assets/img/a1/el-3.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 10
		animation: rotate1
		animation-duration: 38s
		animation-timing-function: linear
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: vwm(120px)
			height: vwm(120px)
			left: vwm(150px)
			top: vwm(361px)
	.el-5
		width: 55px
		height: 255px
		background-image: url(../assets/img/a1/el-4.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		left: 501px
		top: 36px
		animation: updown2
		animation-duration: 10s
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: vwm(40px)
			height: vwm(100px)
			left: vwm(263px)
			top: vwm(-8px)
	.el-1
		width: 85px
		height: 255px
		background-image: url(../assets/img/a1/el-1.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		left: 133px
		top: 120px
		animation: updown
		animation-duration: 8s
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: vwm(46px)
			height: vwm(216px)
			left: vwm(71px)
			top: vwm(60px)
	.f-1
		width: 100%
		height: 100%
		position: absolute
		bottom: 0
		left: 0
		z-index: 6
		background-image: url(../assets/img/a1/f-1.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 59px
		@media screen and (max-width: 1000px)
			background-position: 50% vwm(36px)

	.el-2
		width: 345px
		height: 572px
		background-image: url(../assets/img/a1/el-2.png)
		background-size: contain
		background-repeat: no-repeat
		background-position: 50% 0
		position: absolute
		z-index: 4
		left: 193px
		top: 266px
		@media screen and (max-width: 1000px)
			width: vwm(172px)
			height: vwm(300px)
			left: vwm(120px)
			top: vwm(146px)
	.rellax
		transition: all 900ms
	.product
		height: 818px
		background-image: url(../assets/img/products-bg-1.png)
		background-size: 929px
		margin-top: 63px
		margin-left: 4px
		background-repeat: no-repeat
		position: relative
		@media screen and (max-width: 1000px)
			background-image: url(../assets/img/products-bg-1-m.png)
			height: vwm(574px)
			background-size: 100vw
			margin-top: vwm(-19px)
			margin-left: vwm(0px)
		&__item
			cursor: pointer
			transition: all 300ms
			&:hover
				transform: scale(0.98)
			&--2
				width: 531px
				text-align: center
				position: absolute
				left: 381px
				top: 276px
				@media screen and (max-width: 1000px)
					width: vwm(200px)
					left: vwm(2px)
					top: vwm(115px)
					margin-top: vwm(55px)
					margin-left: vwm(189px)
					.product__item-title
						position: relative
						left: vwm(52px)
						top: vwm(6px)
					.product__item-text
						position: relative
						left: vwm(70px)
						width: vwm(150px)
						top: vwm(8px)
				.product__item-box
					width: 222px
					position: absolute
					left: 315px
					top: 293px
					@media screen and (max-width: 1000px)
						width: vwm(180px)
						left: vwm(-100px)
						top: vwm(308px)
				img
					width: 308px
					margin-left: 5px
					&.mob-only
						display: none
					@media screen and (max-width: 1000px)
						width: vwm(152px)
						&.mob-only
							display: block
						&.desk-only
							display: none
			&--3
				width: 361px
				text-align: center
				position: absolute
				left: 695px
				top: 84px
				@media screen and (max-width: 1000px)
					display: none !important
				.product__item-box
					width: 222px
					position: absolute
					left: 144px
					top: 314px
				img
					width: 126px
					margin-left: 5px
			&--4
				width: 361px
				text-align: center
				position: absolute
				left: 695px
				top: 84px
				.product__item-box
					width: 222px
					position: absolute
					left: 144px
					top: 314px
				img
					width: 126px
					margin-left: 5px
			&--1
				width: 253px
				text-align: center
				position: absolute
				left: 40px
				top: 115px
				@media screen and (max-width: 1000px)
					width: vwm(200px)
					left: vwm(2px)
					top: vwm(115px)
					.product__item-title
						position: relative
						left: vwm(52px)
						top: vwm(6px)
					.product__item-text
						position: relative
						left: vwm(44px)
						top: vwm(4px)
				img
					width: 253px
					margin-left: 5px
					@media screen and (max-width: 1000px)
						width: vwm(195px)
						margin-left: 0
			&-text
				@media screen and (max-width: 1000px)
					font-size: vwm(14px)
			&-title
				font-size: 18px
				color: #48336C
				line-height: 20px
				margin-bottom: 12px
				text-transform: uppercase
				@media screen and (max-width: 1000px)
					font-size: vwm(16px)
					line-height: vwm(20.29px)
					margin-bottom: vwm(10px)
			&-image
				margin-top: 14px
				@media screen and (max-width: 1000px)
					margin-top: vwm(14px)
				img
					maw-width: 100%
					display: block
	.product-m
		display: none
		@media screen and (max-width: 1000px)
			display: block
			background-image: url(../assets/img/product-1-m-bg.png)
			width: vwm(333px)
			margin: auto
			min-height: vwm(383px)
			background-size: 100%
			margin-top: vwm(63px)
			margin-bottom: vwm(65px)
			background-repeat: no-repeat
			.product__item-box
				width: vwm(200px)
				margin: auto
				margin-top: vwm(41px)
				text-align: center
			.product__item-image img
				margin: auto
				position: relative
				top: vwm(38px)
	.arcs
		&__title
			font-size: 26px
			text-transform: uppercase
			color: #48336C
			letter-spacing: 1.5px
			margin-top: 24px
			text-align: center
			@media screen and (max-width: 1000px)
				font-size: vwm(20px)
				line-height: vwm(25.36px)
				letter-spacing: vwm(1.5px)
				span
					display: block
		&__arrows
			width: 812px
			background-image: url(../assets/img/arrows.svg)
			background-repeat: no-repeat
			background-position: 50% 50%
			background-size: contain
			height: 15px
			margin: auto
			margin-top: 8px
			@media screen and (max-width: 1000px)
				width: 100%
				height: vwm(9px)
				margin-top: vwm(0px)
				background-image: url(../assets/img/arrows-m.svg)
		&__subtitle
			font-size: 20px
			color: #48336C
			margin-top: 14px
			text-align: center
			@media screen and (max-width: 1000px)
				font-size: vwm(16px)
				line-height: vwm(20px)
				margin-top: vwm(8px)
		&__text
			display: flex
			justify-content: space-between
			font-size: 18px
			margin-top: 89px
			letter-spacing: -0.1px
			line-height: 23px
			@media screen and (max-width: 1000px)
				font-size: vwm(17px)
				line-height: vwm(21.96px)
				display: block
				width: 92.9%
				margin: auto
				margin-top: vwm(69px)
				br
					display: none
				span
					display: block
			p
				width: 499px
				@media screen and (max-width: 1000px)
					width: 100%
				&:last-child
					position: relative
					left: 3px
		&__image
			position: relative
			mask: url('../assets/img/mask-1.svg')
			margin: auto
			width: 654px
			margin-top: 119px
			background-image: url(../assets/img/a1/bg.jpg)
			height: 818px
			cursor: pointer
			background-size: cover
			@media screen and (max-width: 1000px)
				width: vwm(348px)
				height: vwm(434px)
				margin-top: vwm(115px)
				mask: url('../assets/img/mask-5.svg')
				mask-size: vwm(348px)
		&__right
			width: 461px
			position: absolute
			mask: url('../assets/img/mask-3.svg')
			right: -360px
			top: 123px
			height: 576px
			@media screen and (max-width: 1000px)
				display: none
			img
				z-index: 2
				margin-left: -230px
				display: block
				margin-top: -50px
				position: absolute
			&:after
				content: ''
				display: block
				width: 100%
				height: 100%
				position: absolute
				top: 0
				left: 0
				z-index: 1
				background: linear-gradient(180deg, #D2C8BE 0%, rgba(234, 229, 225, 0.79) 72.4%, rgba(235, 230, 226, 0) 100%)
		&__left
			width: 461px
			position: absolute
			mask: url('../assets/img/mask-3.svg')
			left: -360px
			top: 123px
			height: 576px
			@media screen and (max-width: 1000px)
				display: none
			img
				z-index: 2
				margin-left: -70px
				margin-top: -20px
				display: block
				position: absolute
			&:after
				content: ''
				display: block
				width: 100%
				height: 100%
				position: absolute
				top: 0
				left: 0
				z-index: 1
				background: linear-gradient(180deg, #D2C8BE 0%, rgba(234, 229, 225, 0.79) 72.4%, rgba(235, 230, 226, 0) 100%)
	.arcs__image
		video
			width: 100%
</style>

<template>
	<div class="container">
		<div class="header">
			<div class="header__title" data-aos="fade-down">Специальный проект</div>
			<div class="header__logos">
				<div class="header__arrow header__arrow-1" data-aos="fade-left" data-aos-delay="800"></div>
				<div class="header__logo-lm" data-aos="zoom-in" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866186741&iu=/81006599/hmiru-mc/count', 'LOGO')"><a href="javascript:void(0)"></a></div>
				<div class="header__line" data-aos="zoom-in" data-aos-delay="300"></div>
				<div class="header__logo-mc" data-aos="zoom-in" data-aos-delay="600" @click="openLink('https://marieclaire.ru', 'LOGO_MC')"><a href="javascript:void(0)"></a></div>
				<div class="header__arrow header__arrow-2" data-aos="fade-right" data-aos-delay="800"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HeaderBlock',
		methods: {
			openLink(link, event_label) {
				window.open(link, '_blank').focus();
        this.$gtag.event('click', {event_category: 'link','event_label': event_label})
			},
		}
	}
</script>

<style lang="sass">
	.header
		&__title
			font-size: 20px
			text-align: center
			line-height: 1.2
			padding-top: 29px
			@media screen and (max-width: 1000px)
				display: none
		&__logos
			display: flex
			width: 100%
			justify-content: center
			padding-left: 22px
			padding-top: 19px
			align-items: center
			@media screen and (max-width: 1000px)
				padding: 0
				padding-top: vwm(33px)
		&__arrow
			position: absolute
			width: 352px
			height: 14px
			background-image: url(../assets/img/arrow.svg)
			background-size: contain
			background-repeat: no-repeat
			left: -20px
			top: 86px
			@media screen and (max-width: 1000px)
				width: vwm(82px)
				height: vwm(6px)
				left: vwm(0px)
				top: vwm(39px)
				background-image: url(../assets/img/arrow-3.svg)
			&-2
				right: 0px
				height: 13px
				width: 310px
				left: auto
				background-image: url(../assets/img/arrow-2.svg)
				@media screen and (max-width: 1000px)
					width: vwm(82px)
					height: vwm(6px)
					right: vwm(0px)
					transform: rotate(180deg) !important
					top: vwm(39px)
					background-image: url(../assets/img/arrow-3.svg)
		&__line
			width: 77px
			background-size: contain
			height: 57px
			margin-left: 18px
			background-image: url(../assets/img/line.svg)
			background-repeat: no-repeat
			position: absolute
			@media screen and (max-width: 1000px)
				width: vwm(24px)
				height: vwm(25px)
				background-image: url(../assets/img/line-3.svg)
				margin-left: vwm(-4px)
		&__logo
			&-lm
				width: 155px
				height: 31px
				background-image: url(../assets/img/logo-lamer.png)
				background-size: contain
				cursor: pointer
				background-repeat: no-repeat
				@media screen and (max-width: 1000px)
					width: vwm(79px)
					height: vwm(16px)
			&-mc
				width: 177px
				height: 32px
				background-image: url(../assets/img/logo-mc.png)
				background-size: contain
				cursor: pointer
				background-repeat: no-repeat
				margin-left: 54px
				@media screen and (max-width: 1000px)
					width: vwm(90px)
					height: vwm(16px)
					margin-left: vwm(28px)

</style>
<template>
  <div class="container">
    <div class="footer">
      <div class="footer__logo" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866186741&iu=/81006599/hmiru-mc/count', 'LOGO')"></div>
      <div class="footer__title">Специальный проект</div>
      <div class="footer__content">
        <yandex-share
        class="footer-shares"
        :services="['facebook','vkontakte','odnoklassniki','twitter','telegram']"
        @share="share"
        counter />
      <div class="footer-copyright">© 2021,  HSM Creative Studio</div>
      </div>
    </div>
  </div>
</template>

<script>
  import YandexShare from '@cookieseater/vue-yandex-share';
	export default {
		name: 'FooterBlock',
    components: {
      YandexShare
    },
    data() {
      return {
        team: false,
      }
    },
    methods: {
      share(soc) {
        this.$gtag.event('click', {event_category: 'link','event_label': 'share_'+soc})
      },
      openLink(link, event_label) {
        window.open(link, '_blank').focus();
        this.$gtag.event('click', {event_category: 'link','event_label': event_label})
      },
    }
	}
</script>

<style lang="sass">
  .footer
    width: 100%
    height: 118px
    background-size: contain
    background-image: url(../assets/img/footer.png)
    background-repeat: no-repeat
    margin-top: -101px
    margin-bottom: 30px
    background-position: 50% 43px
    &__logo
      width: 183px
      height: 36px
      position: absolute
      cursor: pointer
      left: 42px
      right: 0
      margin: auto
      top: 43px
    @media screen and (max-width: 1000px)
      margin-top: vwm(104px)
      background-image: url(../assets/img/footer-m.png)
      background-position: 50% vwm(30px)
      height: auto
    &__title
      font-size: 20px
      text-align: center
      line-height: 1.2
      padding-left: 37px
      padding-top: 3px
      @media screen and (max-width: 1000px)
        font-size: vwm(16px)
        padding-left: 0
        padding-top: 0
    &__content
      display: flex
      align-items: center
      justify-content: space-between
      margin-top: 70px
      padding-left: 1px
      @media screen and (max-width: 1000px)
        flex-direction: column
    &-copyright
      color: #2E2A2A
      font-size: 19px
      font-family: Arial
      @media screen and (max-width: 1000px)
        letter-spacing: vwm(1px)
        font-size: vwm(16px)
        line-height: vwm(17.84px)
        padding-top: vwm(44px)
  .ya-share2
    display: flex
    align-items: center
    justify-content: center
    text-align: center
    &__link
      span
        display: none
    &__list
      display: flex !important
      justify-content: center
      align-items: center
      vertical-align: initial !important
      @media screen and (max-width: 1000px)
        margin-top: vwm(27px) !important
    &__item
      display: flex
      align-items: center
      justify-content: center
      margin: 0 !important
      cursor: pointer
      margin-right: 27px !important
      transition: all ease .3s
      a:before
        content: ""
        display: block
        width: 38px
        height: 38px
        margin-right: 18px
        color: #000
        background-position: 0 0
        background-repeat: no-repeat
        background-size: cover
        transition: all .3s ease
      &:last-child
        margin-right: 0 !important
      &:last-child a:before
        margin-right: 0 !important
      &_service_facebook a
        &:before
          background-image: url(../assets/img/social/icon-fb.svg)
          width: 13px
          height: 25px
        &:hover
          &:before
            background-image: url(../assets/img/social/hover/icon-fb.svg) !important
      &_service_vkontakte a
        &:before
          background-image: url(../assets/img/social/icon-vk.svg)
          width: 27px
          height: 16px
        &:hover
          &:before
            background-image: url(../assets/img/social/hover/icon-vk.svg) !important
      &_service_odnoklassniki a
        &:before
          background-image: url(../assets/img/social/icon-ok.svg)
          width: 15px
          height: 25px
        &:hover
          &:before
            background-image: url(../assets/img/social/hover/icon-ok.svg) !important
      &_service_twitter a
        &:before
          background-image: url(../assets/img/social/icon-tw.svg)
          width: 24px
          height: 20px
        &:hover
          &:before
            background-image: url(../assets/img/social/hover/icon-tw.svg) !important
      &_service_telegram a
        &:before
          background-image: url(../assets/img/social/icon-tg.svg)
          width: 27px
          height: 23px
        &:hover
          &:before
            background-image: url(../assets/img/social/hover/icon-tg.svg) !important

</style>


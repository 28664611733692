import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import YandexShare from '@cookieseater/vue-yandex-share';
import VueGtag from "vue-gtag";

Vue.component('yandex-share', YandexShare);


Vue.config.productionTip = false
Vue.use(VueGtag, {
  config: { id: "UA-106465363-10" }
});

new Vue({
	router,
  render: h => h(App),
}).$mount('#app')

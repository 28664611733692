<template>
	<div class="page">
		<HeaderBlock />
		<MainBlock />
		<Arcs />
		<Arcs2 />
		<Arcs3 />
		<Arcs4 />
		<Arcs5 />
		<FooterBlock />
	</div>
</template>

<script>
	import AOS from 'aos'
	import 'aos/dist/aos.css'
	import HeaderBlock from '@/components/HeaderBlock.vue'
	import MainBlock from '@/components/MainBlock.vue'
	import Arcs from '@/components/Arcs.vue'
	import Arcs2 from '@/components/Arcs2.vue'
	import Arcs3 from '@/components/Arcs3.vue'
	import Arcs4 from '@/components/Arcs4.vue'
	import Arcs5 from '@/components/Arcs5.vue'
	import FooterBlock from '@/components/FooterBlock.vue'
	export default {
		name: 'Main',
		components: {
			HeaderBlock,
			MainBlock,
			Arcs,
			Arcs2,
			Arcs3,
			Arcs4,
			Arcs5,
			FooterBlock,
		},
		mounted() {
			AOS.init({
				duration: 800
			});
		},
	}
</script>
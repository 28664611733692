<template>
	<div class="container" id="main">
		<div class="main">
			<div class="main__left" data-aos="fade-up" data-aos-delay="600">
				<div class="main__left-cloud"><div class="main__cloud"></div></div>
				<div class="main__left-stone parallax" data-speed-y="30" data-speed-x="15"></div>
			</div>
			<div class="main__right" data-aos="fade-up" data-aos-delay="1200">
				<div class="main__right-cloud"><div class="main__cloud"></div></div>
				<div class="main__right-stone parallax" data-speed-y="12" data-speed-x="-5"></div>
			</div>
			<div class="main__content" data-aos="fade-up" data-aos-delay="400">
				<div class="main__column"></div>
				<div class="main__column-2"></div>
				<div class="main__stone"></div>
				<div class="main__cloud"></div>
				<div class="main__cloud main__cloud--2"></div>
				<div class="main__label">5 вещей, которые раскроют <br>вашу индивидуальность</div>
				<div class="main__title">
					<span>Искусство<br>быть женщинои</span>
					<div class="main__title-moon"></div>
					<div class="main__title-leaf"></div>
					<div class="main__title-batterfly"></div>
					<div class="main__title-flower"></div>
					<div class="main__title-line"></div>
				</div>
				<div class="main__text desk-only">Женственность всегда индивидуальна, и чтобы раскрыть <br/>ее во всей полноте, важно сосредоточиться не только на внутренней работе, но и на ежедневных бьюти-ритуалах. <br/>О пяти главных составляющих искусства быть женщиной <br/>и о том, как научиться владеть им в совершенстве, —<br/>в нашем специальном материале.</div>
			</div>
			<div class="main__text mob-only">Женственность всегда индивидуальна, и чтобы раскрыть <br/>ее во всей полноте, важно сосредоточиться не только на внутренней работе, но и на ежедневных бьюти-ритуалах. <br/>О пяти главных составляющих искусства быть женщиной <br/>и о том, как научиться владеть им в совершенстве, —<br/>в нашем специальном материале.</div>
			<div class="main__bottom">
				<div class="main__bottom-arrow" data-aos="fade-left"></div>
				<div class="main__bottom-text" data-aos="fade-up">
					Специалисты <a href="https://instagram.com/lamerrussia?utm_medium=copy_link" target="_blank">La Mer</a> отобрали лучшие варианты бьюти-средств для эффективной работы <span></span>с каждой <br/>из основ, распределив их в пять наборов для комплексной заботы о вашей красоте. Искусство быть женщиной никогда еще не было настолько приятным, творческим <span></span>и результативным.
				</div>
				<div data-aos="fade-right">
					<div class="main__bottom-arrow main__bottom-arrow--2"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import gsap from 'gsap';
	export default {
		name: 'MainBlock',
		mounted() {
			window.addEventListener("mousemove", this.parallaxEvent);
		},
		methods: {
			parallaxEvent() {
				var pageX =  event.pageX - (document.getElementById('main').getBoundingClientRect().width * 0.5);
				var pageY =  event.pageY - (document.getElementById('main').getBoundingClientRect().height * 0.5);
				var i = 0;
				let parallaxItems = document.getElementsByClassName('parallax');
				for (i = 0; i < parallaxItems.length; i++) {
						var item = parallaxItems[i];
						var speedX = item.getAttribute("data-speed-x") || 30;
						var speedY = item.getAttribute("data-speed-y") || 30;
						gsap.set(item, {
								x: (item.offsetLeft + pageX * speedX ) * 0.001,
								y: (item.offsetTop + pageY * speedY) * 0.001
						});
				}
			},
		}
	}
</script>

<style lang="sass">
	@keyframes batterflu
		0%
			transform: translate(0, 0) rotate(0)
		25%
			transform: translate(-10px, -20px) rotate(10deg)
		50%
			transform: translate(10px, 0px) rotate(10deg)
		100%
			transform: translate(0, 0) rotate(0)
	@keyframes leaf
		0%
			transform: translate(0, 0) rotate(0)
		25%
			transform: translate(5px, 5px) rotate(-10deg)
		50%
			transform: translate(4px, 2px) rotate(10deg)
		100%
			transform: translate(0, 0) rotate(0)
	@keyframes flower
		0%
			transform: translate(0, 0) rotate(0)
		25%
			transform: translate(15px, 15px) rotate(-10deg)
		50%
			transform: translate(-4px, -2px) rotate(10deg)
		100%
			transform: translate(0, 0) rotate(0)
	@keyframes rotate
		0%
			transform: rotate(0)
		50%
			transform: rotate(380deg)
		100%
			transform: rotate(0)
	@keyframes rotate2
		0%
			transform: rotate(0)
		50%
			transform: rotate(380deg) scale(1.1)
		100%
			transform: rotate(0)
	@keyframes cloud
		0%
			transform: translate(0, 0)
		50%
			transform:  translate(100px, 20px)
		100%
			transform: translate(0, 0)
	@keyframes halfrotate
		0%
			transform: rotate(0)
		50%
			transform: rotate(20deg) scale(1.4)
		100%
			transform: rotate(0)
	.main
		display: flex
		flex-direction: column
		&__bottom
			width: 863px
			margin: auto
			margin-top: 20px
			@media screen and (max-width: 1000px)
				width: 90%
				margin-top: vwm(89px)
			&-arrow
				width: 352px
				height: 13px
				background-image: url(../assets/img/arrow.svg)
				@media screen and (max-width: 1000px)
					width: vwm(356px)
					height: vwm(13.4px)
					background-size: contain
					background-repeat: no-repeat
					position: relative
					left: vwm(-8px)
				&--2
					transform: rotate(180deg)
					margin-left: auto
					margin-top: 45px
					@media screen and (max-width: 1000px)
						right: vwm(-8px)
						margin-top: vwm(36px)
			&-text
				width: 706px
				margin: auto
				font-size: 20px
				text-transform: uppercase
				color: #48336C
				text-align: center
				line-height: 25.36px
				margin-top: 48px
				a
					color: #48336C
					text-decoration: underline
				@media screen and (max-width: 1000px)
					width: 100%
					font-size: vwm(18px)
					line-height: vwm(23px)
					margin-top: vwm(40px)
					br
						display: none
					span
						display: block
		&__label
			text-align: center
			font-size: 24px
			padding-top: 99px
			line-height: 30px
			position: relative
			z-index: 6
			padding-left: 17px
			@media screen and (max-width: 1000px)
				font-size: vwm(20px)
				padding-top: vwm(76px)
				line-height: vwm(25px)
				padding-left: vwm(43px)
				padding-right: vwm(50px)
				order: 7
				br
					display: none
		&__stone
			width: 535px
			height: 313px
			background-image: url(../assets/img/stone.png)
			background-size: contain
			position: absolute
			right: -196px
			z-index: 1
			top: 548px
			@media screen and (max-width: 1000px)
				width: vwm(337px)
				height: vwm(197px)
				right: vwm(-138px)
				top: vwm(391px)
		&__column
			width: 328px
			height: 840px
			background-image: url(../assets/img/column.png)
			background-size: contain
			position: absolute
			left: -112px
			top: -20px
			z-index: 2
			@media screen and (max-width: 1000px)
				width: vwm(192px)
				height: vwm(493px)
				left: vwm(-72px)
				top: vwm(31px)
			&-2
				width: 185px
				height: 730px
				background-image: url(../assets/img/column-2.png)
				background-size: contain
				position: absolute
				right: -93px
				top: -75px
				z-index: 2
				@media screen and (max-width: 1000px)
					width: vwm(109px)
					height: vwm(429px)
					right: vwm(-58px)
					top: vwm(31px)
		&__title
			text-align: center
			font-size: 130px
			color: #48336C
			text-transform: uppercase
			font-family: 'Sverdlovsk'
			padding-top: 49px
			padding-left: 21px
			line-height: 120px
			z-index: 6
			position: relative
			@media screen and (max-width: 1000px)
				font-size: vwm(70px)
				line-height: 0.9
				padding: vwm(109px) vwm(10px) 0 vwm(10px)
				order: 10
			span
				position: relative
				z-index: 6
			&-moon
				width: 133px
				height: 133px
				background-image: url(../assets/img/moon.png)
				background-repeat: no-repeat
				background-size: contain
				position: absolute
				left: 692px
				top: 164px
				animation: rotate
				animation-iteration-count: infinite
				animation-duration: 25s
				@media screen and (max-width: 1000px)
					width: vwm(73px)
					height: vwm(73px)
					left: vwm(204px)
					top: vwm(223px)
			&-line
				width: 70px
				height: 50px
				background-image: url(../assets/img/line-2.svg)
				background-repeat: no-repeat
				background-size: contain
				position: absolute
				left: 738px
				top: 85px
				@media screen and (max-width: 1000px)
					width: vwm(25px)
					height: vwm(25px)
					left: vwm(285px)
					top: vwm(122px)
			&-leaf
				width: 42px
				height: 42px
				background-image: url(../assets/img/leaf.png)
				background-repeat: no-repeat
				background-size: contain
				position: absolute
				left: 807px
				top: 141px
				animation: leaf
				animation-iteration-count: infinite
				animation-duration: 5s
				animation-timing-function: linear
				@media screen and (max-width: 1000px)
					width: vwm(35px)
					height: vwm(30px)
					left: vwm(259px)
					top: vwm(206px)
			&-flower
				width: 115px
				height: 66px
				background-image: url(../assets/img/flower.png)
				background-repeat: no-repeat
				background-size: contain
				position: absolute
				left: 117px
				top: 190px
				animation: flower
				animation-iteration-count: infinite
				animation-duration: 25s
				animation-timing-function: linear
				@media screen and (max-width: 1000px)
					width: vwm(72px)
					height: vwm(95px)
					left: vwm(13px)
					top: vwm(236px)
			&-batterfly
				width: 44px
				height: 54px
				background-image: url(../assets/img/batterfly.png)
				background-repeat: no-repeat
				background-size: contain
				position: absolute
				left: 314px
				top: 20px
				animation: batterflu
				animation-iteration-count: infinite
				animation-duration: 10s
				@media screen and (max-width: 1000px)
					width: vwm(32px)
					height: vwm(33px)
					left: vwm(52px)
					top: vwm(81px)
		&__text
			width: 532px
			margin: auto
			margin-top: 70px
			text-align: center
			font-size: 18px
			position: relative
			z-index: 6
			left: 10px
			line-height: 23px
			&.mob-only
				display: none
			@media screen and (max-width: 1000px)
				font-size: vwm(17px)
				line-height: vwm(22.06px)
				margin-top: vwm(-212px)
				left: 0
				width: vwm(339px)
				&.mob-only
					display: block
				&.desk-only
					display: none
				br
					display: none
		&__cloud
			width: 598px
			height: 229px
			background-image: url(../assets/img/cloud-1.png)
			background-repeat: no-repeat
			background-size: contain
			position: absolute
			z-index: 4
			top: 0px
			left: -60px
			animation: cloud
			animation-iteration-count: infinite
			animation-duration: 25s
			animation-timing-function: linear
			@media screen and (max-width: 1000px)
				width: vwm(252px)
				height: vwm(96px)
				left: vwm(-10px)
				top: vwm(12px)
			&--2
				top: 290px
				left: auto
				right: -300px
				animation: cloud
				animation-iteration-count: infinite
				animation-duration: 15s
				animation-direction: reverse
				@media screen and (max-width: 1000px)
					width: vwm(453px)
					height: vwm(173px)
					right: vwm(-261px)
					top: vwm(152px)
		&__content
			width: 1038px
			height: 805px
			background-size: contain
			background-repeat: no-repeat
			margin-top: 70px
			position: relative
			mask: url('../assets/img/mask.svg')
			@media screen and (max-width: 1000px)
				width: vwm(348px)
				margin: auto
				margin-top: vwm(29px)
				background-repeat: no-repeat
				height: vwm(786px)
				mask: url('../assets/img/mask-4.svg')
				mask-size: vwm(348px)
				mask-repeat: no-repeat
				display: flex
				justify-content: flex-end
				flex-direction: column-reverse
			&:before
				content: ''
				display: block
				width: 100%
				height: 100%
				position: absolute
				z-index: 3
				background: linear-gradient(180deg, #D2C8BE 0%, rgba(234, 229, 225, 0.59) 72.4%, rgba(235, 230, 226, 0) 100%)
		&__left
			width: 756px
			height: 576px
			background-repeat: no-repeat
			background-position: 50% 50%
			background-size: contain
			mask: url('../assets/img/mask-2.svg')
			mask-size: 756px
			background: linear-gradient(180deg, #D2C8BE 0%, rgba(234, 229, 225, 0.79) 72.4%, rgba(235, 230, 226, 0) 100%)
			left: -841px
			top: 200px
			position: absolute
			&-stone
				position: absolute
				width: 413px
				height: 357px
				background-image: url(../assets/img/stone-1.png)
				right: -100px
				top: 20px
				opacity: 0.6
			&-cloud
				margin-left: 200px
				position: relative
				z-index: 1
				opacity: 0.6
				.main__cloud
					animation-duration: 10s
			&:before
				content: ''
				display: block
				width: 100%
				height: 100%
				position: absolute
				z-index: 2
				animation: cloud
				animation-iteration-count: infinite
				animation-duration: 25s
				animation-timing-function: linear
			@media screen and (max-width: 1000px)
				display: none
		&__right
			width: 756px
			height: 576px
			background-repeat: no-repeat
			background-position: 50% 50%
			background-size: contain
			mask: url('../assets/img/mask-2.svg')
			mask-size: 756px
			background: linear-gradient(180deg, #D2C8BE 0%, rgba(234, 229, 225, 0.79) 72.4%, rgba(235, 230, 226, 0) 100%)
			right: -817px
			top: 200px
			position: absolute
			&-stone
				position: absolute
				width: 413px
				height: 357px
				background-image: url(../assets/img/stone-1.png)
				left: -100px
				top: 20px
				opacity: 0.2
			&-cloud
				margin-left: 0px
				position: relative
				z-index: 1
				opacity: 0.6
				.main__cloud
					animation-duration: 10s
			@media screen and (max-width: 1000px)
				display: none
</style>
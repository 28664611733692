<template>
	<div class="arcs">
		<div class="container">
			<div class="arcs__left">
				<img src="../assets/img/arc-5l.png" alt="">
				<img src="../assets/img/arc-5l2.png"  class="anim-colud" alt="">
			</div>
			<div class="arcs__image" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866185814&iu=/81006599/hmiru-mc/count', 'COLLECTION_5')">
				<div class="el-5-1"></div>
				<div class="el-5-2"></div>
				<div class="el-5-3"></div>
				<div class="el-5-4"></div>
				<div class="el-5-5"></div>
				<div class="el-5-6"></div>
				<div class="el-5-7"></div>
				<div class="el-5-8"></div>
				<div class="el-5-9"></div>
				<div class="el-5-10"></div>
				<div class="el-5-11"></div>
				<div class="el-5-12"></div>
			</div>
			<div class="arcs__right">
				<img src="../assets/img/arc-5r.png"  class="anim-half-rotate" alt="">
			</div>
			<div class="arcs__content">
				<div class="arcs__title" data-aos="fade">Искусство <span></span>быть утонченной</div>
				<div class="arcs__arrows" data-aos="zoom-in"></div>
				<div class="arcs__subtitle" data-aos="fade">Смягчение и успокоение кожи <span></span>с The Deep Soothing Collection от La Mer</div>
				<div class="arcs__text" data-aos="fade">
					<p>Утонченная женственность самодостаточна <br/>и гармонична. Подчеркнуть эти качества в своей внешности – порой нелегкая задача, учитывая то количество раздражителей, которое влияет на нее каждый день. Впрочем, раскрыть в себе эту сторону женственности могут помочь и уходовые средства, <br/>ведь на общее восприятие образа во многом влияют внешние характеристики человека – стиль <span></span>одежды, особенности комплекции <span></span>и состояние кожи.</p>
					<div class="product-m">
						<div class="product-m--1" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866851038&iu=/81006599/hmiru-mc/count', 'PRODUCT_8')">
							<div class="product__item-image"><img src="../assets/img/p-13.png" alt=""></div>
							<div class="product__item-box">
								<div class="product__item-title">The Concentrate <br>de la mer</div>
								<div class="product__item-text">Концентрат для лица</div>
							</div>
						</div>
						<div class="product-m--2" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866186222&iu=/81006599/hmiru-mc/count', 'PRODUCT_5')">
							<div class="product__item-box">
								<div class="product__item-title">Crème de <span></span><br>la Mer</div>
								<div class="product__item-text">Увлажняющий крем</div>
							</div>
							<div class="product__item-image"><img src="../assets/img/p-14.png" alt=""></div>
						</div>
					</div>
					<p>Добиться чувственного, утонченного бьюти-образа поможет набор The Deep Soothing Collection от <br/>La Mer, в который вошли два культовых средства бренда. Увлажняющий крем Crème de la Mer <br/>с живительным эликсиром Miracle Broth и концентрат The Concentrate – эффективное комбо в борьбе <br/>за спокойную кожу с крепким защитным барьером. <br/>Чтобы взять от использования сета максимум, наносите сначала концентрат и только затем увлажняющий крем.</p>
				</div>
			</div>
			<div class="product">
					<div class="product__item product__item--1" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866851038&iu=/81006599/hmiru-mc/count', 'PRODUCT_8')">
						<div class="product__item-image"><img src="../assets/img/p-13.png" alt=""></div>
						<div class="product__item-box">
							<div class="product__item-title">The Concentrate <br>de la mer</div>
							<div class="product__item-text">Концентрат для лица</div>
						</div>
					</div>
				<div class=" rellax"  data-rellax-speed="-0.2" data-rellax-percentage="0.5">
					<div class="product__item product__item--2" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866186222&iu=/81006599/hmiru-mc/count', 'PRODUCT_5')">
						<div class="product__item-box">
							<div class="product__item-title">Crème de <br>la Mer</div>
							<div class="product__item-text">Увлажняющий крем</div>
						</div>
						<div class="product__item-image"><img src="../assets/img/p-14.png" alt=""></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Arcs',
		methods: {
			openLink(link, event_label) {
				window.open(link, '_blank').focus();
        this.$gtag.event('click', {event_category: 'link','event_label': event_label})
			},
		}
	}
</script>

<style lang="sass" scoped>
	.arcs__image--desk
		display: block
		@media screen and (max-width: 1000px)
			display: none
	.arcs__image--mob
		display: none
		@media screen and (max-width: 1000px)
			display: block
	@keyframes rotate44
		0%
			transform: rotate(360deg)
		80%
			transform: rotate(0deg)
		100%
			transform: rotate(360deg)
	@keyframes rotate77
		0%
			transform: rotate(0deg)
		50%
			transform: rotate(-60deg)
		100%
			transform: rotate(0deg)
	@keyframes rotate22
		0%
			transform: rotate(0deg)
		80%
			transform: rotate(100deg)
		100%
			transform: rotate(0deg)
	@keyframes rotate33
		0%
			transform: rotate(0deg)
		80%
			transform: rotate(-20deg)
		100%
			transform: rotate(0deg)
	@keyframes rotate55
		0%
			transform: rotate(300deg)
		80%
			transform: rotate(120deg)
		100%
			transform: rotate(300deg)
	@keyframes updown5
		0%
			transform: translateY(0px)
		60%
			transform: translateY(-150px)
		100%
			transform: translateY(0px)
	.el-5-1
		width: 445px
		height: 437px
		display: block
		background-image: url(../assets/img/a5/el-1.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 1
		top: 67px	
		left: 103px
		animation: rotate
		animation-duration: 43s
		animation-timing-function: linear
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 63vw
			height: 63vw
			left: 10vw
			right: 10vw
			margin: auto
			top: 9vw
	.el-5-2
		width: 343px
		height: 347px
		display: block
		background-image: url(../assets/img/a5/el-1.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 2
		top: 111px	
		left: 160px
		animation: rotate44
		animation-duration: 43s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 46vw
			height: 46vw
			left: 10vw
			right: 10vw
			margin: auto
			top: 16vw
	.el-5-3
		width: 575px
		height: 324px
		display: block
		background-image: url(../assets/img/a5/el-2.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 2
		top: 20px	
		left: -236px
		animation: cloud
		animation-duration: 10s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 76vw
			height: 76vw
			left: -46vw
			top: -9vw
	.el-5-4
		width: 575px
		height: 324px
		display: block
		background-image: url(../assets/img/a5/el-3.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 2
		top: 20px	
		left: 336px
		animation: cloud
		animation-duration: 20s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 76vw
			height: 76vw
			right: -46vw
			left: auto
			top: 2vw
	.el-5-5
		width: 568px
		height: 431px
		display: block
		background-image: url(../assets/img/a5/el-9.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 2
		top: 241px	
		left: 39px
		animation: rotate77
		animation-duration: 30s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 66vw
			height: 66vw
			left: 10vw
			right: 10vw
			margin: auto
			top: 46vw
	.el-5-6
		width: 568px
		height: 541px
		display: block
		background-image: url(../assets/img/a5/el-10.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 3
		top: 240px	
		left: 141px
		@media screen and (max-width: 1000px)
			width: 76vw
			height: 76vw
			left: 19vw
			right: 10vw
			margin: auto
			top: 34vw
	.el-5-7
		width: 146px
		height: 248px
		display: block
		background-image: url(../assets/img/a5/el-4.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 2
		top: 52px
		left: 148px
		animation: rotate33
		animation-duration: 6s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 17vw
			height: 29vw
			left: -22vw
			right: 10vw
			margin: auto
			top: 18vw
	.el-5-8
		width: 229px
		height: 178px
		display: block
		background-image: url(../assets/img/a5/el-6.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 10
		top: 585px
		left: 58px
		@media screen and (max-width: 1000px)
			width: 36vw
			height: 46vw
			left: -32vw
			right: 10vw
			margin: auto
			top: 82vw
	.el-5-9
		width: 128px
		height: 127px
		display: block
		background-image: url(../assets/img/a5/el-7.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 9
		top: 585px
		left: 101px
		animation: updown5
		animation-duration: 7s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 19vw
			height: 29vw
			left: -32vw
			right: 10vw
			margin: auto
			top: 82vw
	.el-5-10
		width: 254px
		height: 172px
		display: block
		background-image: url(../assets/img/a5/el-5.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 9
		top: 293px
		left: 309px
		animation: rotate
		animation-duration: 40s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 29vw
			height: 29vw
			left: 44vw
			right: 10vw
			margin: auto
			top: 39vw
	.el-5-11
		width: 109px
		height: 149px
		display: block
		background-image: url(../assets/img/a5/el-8.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 7
		top: 358px
		left: 32px
		animation: rotate55
		animation-duration: 40s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 29vw
			height: 29vw
			left: -49vw
			right: 10vw
			margin: auto
			top: 52vw
	.el-5-12
		width: 144px
		height: 156px
		display: block
		background-image: url(../assets/img/a5/el-8.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 7
		top: 553px
		left: 242px
		animation: rotate22
		animation-duration: 40s
		animation-timing-function: linear
		animation-direction: reverse
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 29vw
			height: 29vw
			left: 3vw
			right: 10vw
			margin: auto
			top: 76vw
	.anim-colud
		animation: cloud
		animation-duration: 10s
		animation-iteration-count: infinite
	.anim-half-rotate
		animation: halfrotate
		animation-duration: 10s
		animation-iteration-count: infinite
	.product-m
		@media screen and (max-width: 1000px)
			background-image: url(../assets/img/products-bg-5-m.png)
			margin-top: vwm(121px)
			margin-bottom: vwm(67px)
			background-size: 100vw
			min-height: vwm(445px)
			margin-left: vwm(-15px)
			width: 100vw
			background-position: 0 0
			display: flex
			flex-direction: column
			.product-m--1
				margin-top: vwm(-7px)
				.product__item-box
					margin-left: vwm(173px)
					margin-top: vwm(-439px)
				.product__item-text
					width: vwm(153px)
					margin-left: vwm(26px)
					margin-top: vwm(-10px)
					margin-bottom: vwm(-50px)
				.product__item-image
					img
						width: vwm(300px)
						position: relative
						top: vwm(-56px)
						left: vwm(-46px)
						transform: rotate(-2deg)
			.product-m--2
				margin-top: vwm(126px)
				margin-left: vwm(200px)
				.product__item-box
					margin-left: vwm(7px)
					margin-top: vwm(262px)
					position: absolute
					width: vwm(160px)
				.product__item-text
					width: vwm(153px)
					margin-left: vwm(1px)
					margin-top: vwm(-7px)
					margin-bottom: vwm(-50px)
				.product__item-image
					img
						width: vwm(169px)
						position: relative
						margin-left: 0px
	.arcs
		margin-top: -189px
		@media screen and (max-width: 1000px)
			margin-top: vwm(-20px)
	.arcs__left img
		margin-left: 79px
		margin-top: 5px
	.arcs__right img
		margin-left: -84px
		margin-top: -4px
	.arcs__subtitle
		@media screen and (max-width: 1000px)
			span
				display: block
	.arcs__image
		background-image: url(../assets/img/a5/bg.jpg)
	.arcs__left
		&:after
			background: linear-gradient(180deg, #485275 0%, rgba(162, 167, 186, 0.56) 71.35%, rgba(211, 214, 223, 0.42) 100%)
	.arcs__right
		&:after
			background: linear-gradient(180deg, #485275 0%, rgba(162, 167, 186, 0.56) 71.35%, rgba(211, 214, 223, 0.42) 100%)
	.product
		background-image: url(../assets/img/product-bg-5.png)
		margin-top: 47px
		margin-left: 0px
		background-size: 691px
		background-position: 165px 43px
		@media screen and (max-width: 1000px)
			display: none
		&__item
			&--1
				z-index: 2
				img
					width: 415px
					margin-top: -127px
					margin-left: 198px
				.product__item-box
					width: 222px
					margin-left: -30px
					margin-top: -103px
			&--2
				img
					width: 248px
					margin-top: -123px
					margin-left: 218px
					z-index: 3
					position: relative
				.product__item-box
					width: 222px
					margin-left: 92px
					margin-top: -446px
</style>
<template>
	<div class="arcs">
		<div class="container">
			<div class="arcs__left">
				<img src="../assets/img/arc-3l.png" class="parallax" data-speed-y="-10" data-speed-x="15"  alt="">
			</div>
			<div class="arcs__image" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866185799&iu=/81006599/hmiru-mc/count', 'COLLECTION_3')">
				<div class="el-3-1"></div>
				<div class="el-3-2"></div>
				<div class="el-3-3"></div>
				<div class="el-3-4"></div>
				<div class="el-3-5"></div>
				<div class="el-3-6"></div>
				<div class="el-3-7"></div>
				<div class="el-3-8"></div>
				<div class="el-3-9"></div>
				<!-- <div class="el-3-10"></div> -->
			</div>
			<div class="arcs__right">
				<img src="../assets/img/arc-3r.png" class="parallax" data-speed-y="10" data-speed-x="-15"  alt="">
				<img src="../assets/img/arc-3r1.png" class="anim-rotate" alt="">
			</div>
			<div class="arcs__content">
				<div class="arcs__title" data-aos="fade">Искусство <span></span>быть яркой</div>
				<div class="arcs__arrows" data-aos="zoom-in"></div>
				<div class="arcs__subtitle" data-aos="fade">Восстановление кожи с The Rejuvenating Rituals Collection от La Mer</div>
				<div class="arcs__text" data-aos="fade">
					<p>Важная составляющая яркой женственности – сияющая, заряженная энергией кожа. К сожалению, высокие темпы современной жизни плохо сказываются на ее здоровье: регулярные недосыпы, несбалансированное питание <span></span>и плотный рабочий график часто <span></span>не оставляют шансов на сияющий внешний вид и приводят к тусклости <span></span>и общей ослабленности кожи. Поэтому на помощь приходят поддерживающие уходовые средства, которые призваны минимизировать негативное воздействие окружающей среды <span></span>и позволить вам выглядеть ярко каждый день.</p>
					<div class="product-m">
						<div class="product-m--1" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866851020&iu=/81006599/hmiru-mc/count', 'PRODUCT_6')">
							<div class="product__item-box">
								<div class="product__item-title">The Regenerating Serum</div>
								<div class="product__item-text">Регенерирующая сыворотка</div>
							</div>
							<div class="product__item-image"><img src="../assets/img/p-8.png" alt=""></div>
						</div>
						<div class="product-m--2" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925009&iu=/81006599/hmiru-mc/count', 'PRODUCT_1')">
							<div class="product__item-image"><img src="../assets/img/p-9.png" alt=""></div>
							<div class="product__item-box">
								<div class="product__item-title">The Treatment Lotion</div>
								<div class="product__item-text">Лосьон для лица</div>
							</div>
						</div>
					</div>
					<p>Отличное решение подобного ухода – набор The Rejuvenating Rituals Collection от La Mer с четырьмя средствами для глубокого восстановления. Лосьон The Treatment Lotion помогает увлажнить кожу и зарядить ее энергией на клеточном уровне. Регенерирующая сыворотка The Regenerating Serum визуально сокращает количество мимических и возрастных морщин, возвращая коже молодой вид. Концентрат для области вокруг глаз The Eye Concentrate дарит коже гладкость, высвобождая ее внутреннее сияние. Увлажняющий крем Crème de la Mer идеально подойдет для питания и насыщения кожи влагой.</p>
				</div>
			</div>
			<div class="product">
				<div class=" rellax"  data-rellax-speed="-0.2" data-rellax-percentage="0.5">
					<div class="product__item product__item--1" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866186222&iu=/81006599/hmiru-mc/count', 'PRODUCT_5')">
						<div class="product__item-image"><img src="../assets/img/p-7.png" alt=""></div>
						<div class="product__item-box">
							<div class="product__item-title">Crème de <br>la Mer</div>
							<div class="product__item-text">Увлажняющий крем</div>
						</div>
					</div>
				</div>
				<div class=" rellax"  data-rellax-speed="0.2" data-rellax-percentage="0.5">
					<div class="product__item product__item--2" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866851020&iu=/81006599/hmiru-mc/count', 'PRODUCT_6')">
						<div class="product__item-box">
							<div class="product__item-title">The Regenerating Serum</div>
							<div class="product__item-text">Регенерирующая сыворотка</div>
						</div>
						<div class="product__item-image"><img src="../assets/img/p-8.png" alt=""></div>
					</div>
				</div>
				<div class="product__item product__item--3" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5864925009&iu=/81006599/hmiru-mc/count', 'PRODUCT_1')">
					<div class="product__item-box">
						<div class="product__item-title">The Treatment Lotion</div>
						<div class="product__item-text">Лосьон для лица</div>
					</div>
					<div class="product__item-image"><img src="../assets/img/p-9.png" alt=""></div>
				</div>
				<div class="product__item product__item--4" @click="openLink('http://pubads.g.doubleclick.net/gampad/clk?id=5866851026&iu=/81006599/hmiru-mc/count', 'PRODUCT_7')">
					<div class="product__item-box">
						<div class="product__item-title">The Eye Concentrate</div>
						<div class="product__item-text">Концентрат для области вокруг глаз</div>
					</div>
					<div class="product__item-image" v-if="toggleProduct"><img src="../assets/img/p-10.png" alt=""></div>
					<div class="product__item-image" v-else><img src="../assets/img/p-10-2.png" alt=""></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Arcs',
		data() {
			return {
				toggleProduct: false,
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll);
		},
		methods: {
			openLink(link, event_label) {
				window.open(link, '_blank').focus();
        this.$gtag.event('click', {event_category: 'link','event_label': event_label})
			},
			handleScroll() {
				let fullH = document.documentElement.getBoundingClientRect().height;
				let topH = window.pageYOffset;
				let procentScroll = topH/fullH*100;
				if (procentScroll > 58) {
					this.toggleProduct = true;
				} else {
					this.toggleProduct = false;
				}
			},
		}
	}
</script>

<style lang="sass" scoped>
	.arcs__image--desk
		display: block
		@media screen and (max-width: 1000px)
			display: none
	.arcs__image--mob
		display: none
		@media screen and (max-width: 1000px)
			display: block
	@keyframes zoom1
		0%
			transform: scale(0.8) rotate(0)
		50%
			transform: scale(1.1) rotate(0)
		100%
			transform: scale(0.8) rotate(0)
	@keyframes rotate3
		0%
			transform: rotate(80deg)
		50%
			transform: rotate(0deg)
		100%
			transform: rotate(80deg)
	@keyframes rotate4
		0%
			transform: rotate(30deg)
		50%
			transform: rotate(80deg)
		100%
			transform: rotate(30deg)
	.el-3-1
		display: block
		width: 523px
		height: 523px
		background-image: url(../assets/img/a3/el-1.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 2
		animation: rotate
		animation-duration: 35s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
		top: 48px
		left: 68px
		@media screen and (max-width: 1000px)
			width: 72vw
			height: 72vw
			left: 10vw
			right: 10vw
			margin: auto
			top: 7vw
	.el-3-2
		display: block
		width: 142px
		height: 142px
		background-image: url(../assets/img/a3/el-1.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 1
		animation: rotate
		animation-duration: 35s
		animation-timing-function: linear
		animation-iteration-count: infinite
		top: 48px
		left: 90px
		@media screen and (max-width: 1000px)
			width: 20vw
			height: 20vw
			left: 8vw
			top: 6vw
	.el-3-3
		display: block
		width: 578px
		height: 567px
		background-image: url(../assets/img/a3/el-2.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 6
		top: 251px
		left: 36px
		@media screen and (max-width: 1000px)
			width: 83vw
			height: 90vw
			left: 0
			right: 0
			margin: auto
			top: 36vw
	.el-3-4
		display: block
		width: 237px
		height: 812px
		background-image: url(../assets/img/a3/el-5.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 3
		top: 66px
		left: 256px
		animation: zoom1
		animation-duration: 8s
		animation-timing-function: linear
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 40vw
			height: 130vw
			left: 4vw
			right: 0
			margin: auto
			top: 16vw
	.el-3-5
		display: block
		width: 114px
		height: 494px
		background-image: url(../assets/img/a3/el-3.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 3
		top: 385px
		left: 535px
		animation: updown
		animation-duration: 4s
		animation-timing-function: linear
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 20vw
			height: 90vw
			left: 75vw
			top: 52vw
	.el-3-6
		display: block
		width: 87px
		height: 265px
		background-image: url(../assets/img/a3/el-6.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 3
		top: 120px
		left: 553px
		animation: updown
		animation-duration: 5s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
		@media screen and (max-width: 1000px)
			width: 10vw
			height: 30vw
			left: 80vw
			top: 12vw
	.el-3-7
		display: block
		width: 98px
		height: 298px	
		background-image: url(../assets/img/a3/el-7.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 7
		top: 522px
		left: 182px
		animation: updown
		animation-duration: 5s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
		@media screen and (max-width: 1000px)
			width: 10vw
			height: 30vw
			left: 24vw
			top: 86vw
	.el-3-8
		display: block
		width: 480px
		height: 682px	
		background-image: url(../assets/img/a3/el-4.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 7
		top: 622px
		left: 182px
		animation: rotate3
		animation-duration: 25s
		animation-timing-function: linear
		animation-iteration-count: infinite
		animation-direction: reverse
		@media screen and (max-width: 1000px)
			width: 50vw
			height: 90vw
			left: 24vw
			top: 86vw
	.el-3-9
		display: block
		width: 480px
		height: 682px	
		background-image: url(../assets/img/a3/el-4.png)
		background-repeat: no-repeat
		background-size: contain
		position: absolute
		z-index: 7
		top: 622px
		left: -100px
		animation: rotate4
		animation-duration: 33s
		animation-timing-function: linear
		animation-iteration-count: infinite
		@media screen and (max-width: 1000px)
			width: 50vw
			height: 90vw
			left: -14vw
			top: 86vw
	.anim-rotate
		animation: rotate
		animation-duration: 180s
		animation-timing-function: linear
		animation-iteration-count: infinite
	.product-m
		@media screen and (max-width: 1000px)
			background-image: url(../assets/img/product-3-m-bg.png)
			margin-top: vwm(157px)
			margin-bottom: vwm(50px)
			background-size: vwm(347px)
			min-height: vwm(445px)
			width: vwm(347px)
			display: flex
			flex-direction: column
			.product-m--1
				margin-top: vwm(-164px)
				.product__item-box
					margin-left: vwm(155px)
					margin-top: vwm(78px)
				.product__item-text
					width: vwm(153px)
					margin-left: vwm(26px)
					margin-top: vwm(-10px)
					margin-bottom: vwm(-50px)
				.product__item-image
					img
						width: vwm(196px)
						position: relative
						top: vwm(-45px)
						left: vwm(-75px)
						transform: rotate(-4deg)
			.product-m--2
				margin-top: vwm(-238px)
				.product__item-box
					margin-left: vwm(7px)
					margin-top: vwm(-37px)
				.product__item-text
					width: vwm(153px)
					margin-left: vwm(26px)
					margin-top: vwm(-4px)
					margin-bottom: vwm(-50px)
				.product__item-image
					img
						width: vwm(258px)
						position: relative
						margin-left: vwm(120px)
	.arcs
		margin-top: -152px
		@media screen and (max-width: 1000px)
			margin-top: vwm(-117px)
	.arcs__left img
		margin-left: 39px
		margin-top: -21px
	.arcs__right img
		margin-left: 46px
		margin-top: -94px
	.arcs__image
		background-image: url(../assets/img/a3/bg.jpg)
	.arcs__text
		@media screen and (max-width: 1000px)
			font-size: vwm(18px)
			line-height: vwm(23px)
			width: 94%
	.arcs__left
		&:after
			background: linear-gradient(180deg, #825977 0%, rgba(132, 91, 121, 0.58) 40.1%, rgba(218, 219, 223, 0) 100%, rgba(225, 215, 223, 0.42) 100%)
	.arcs__right
		&:after
			background: linear-gradient(180deg, #825977 0%, rgba(132, 91, 121, 0.58) 40.1%, rgba(218, 219, 223, 0) 100%, rgba(225, 215, 223, 0.42) 100%)
	.product
		background-image: url(../assets/img/product-bg-3.png)
		margin-top: 47px
		margin-left: 0px
		background-size: 1396px
		background-position: -194px 0
		@media screen and (max-width: 1000px)
			background-image: url(../assets/img/products-bg-3-m.png)
			background-size: vwm(360px)
			margin-top: vwm(169px)
			height: vwm(250px)
			background-position: 0 0
		&__item
			&--1
				img
					width: 274px
					margin-top: 232px
					margin-left: -24px
				.product__item-box
					width: 222px
					margin-left: -72px
					margin-top: -311px
					@media screen and (max-width: 1000px)
						width: vwm(222px)
				@media screen and (max-width: 1000px)
					display: flex
					flex-direction: column
					top: vwm(-130px)
					img
						width: vwm(183px)
						margin-top: vwm(0px)
						margin-left: vwm(0px)
					.product__item-box
						margin: 0
						margin-top: vwm(30px)
						margin-left: vwm(-71px)
			&--2
				@media screen and (max-width: 1000px)
					display: none
				img
					width: 284px
					margin-top: -276px
					margin-left: -110px
				.product__item-box
					width: 222px
					margin-left: -503px
					margin-top: -516px
			&--3
				@media screen and (max-width: 1000px)
					display: none
				img
					width: 309px
					margin-top: 165px
					margin-left: -290px
				.product__item-box
					width: 222px
					margin-left: -398px
					margin-top: 269px
			&--4
				@media screen and (max-width: 1000px)
					left: 0
				img
					width: 240px
					margin-top: 186px
					margin-left: 61px
				.product__item-box
					width: 222px
					margin-left: -1px
					margin-top: -227px
					@media screen and (max-width: 1000px)
						left: vwm(144px)
						top: vwm(314px)
				@media screen and (max-width: 1000px)
					display: flex
					flex-direction: column-reverse
					top: vwm(-30px)
					left: vwm(186px)
					img
						width: vwm(183px)
						margin-left: 0
						margin-top: vwm(-3px)
					.product__item-box
						margin: 0
						margin-top: vwm(-390px)
						margin-left: vwm(-154px)
						width: vwm(200px)
</style>